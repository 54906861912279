<template>
    <div class="putInto">
        <van-cell title="素材类型" :value="infoTypeText" @click="showInfoTypeList=true"/>
        <van-popup v-model="showInfoTypeList" round position="bottom">
            <van-picker title="选择类型" show-toolbar
                        :columns="infoTypeList"
                        @cancel="showInfoTypeList = false"
                        @confirm="changeInfoType"
            />
        </van-popup>
        <van-field label="商品名称" v-model="querySearch.className" placeholder="请输入"/>
        <div class="button-box">
            <van-button type="primary" color="#3F7C53" size="small" :data-clipboard-text="authorizationLink"
                        class="m-l-15" @click="copyAuthorizationLink">复制授权链接
            </van-button>
            <van-button type="primary" color="#3F7C53" size="small"
                        class="m-l-15" @click="search">查 询
            </van-button>

        </div>

        <van-pull-refresh v-model="isLoadingGoods"
                          @refresh="goodsOnRefresh">
            <van-list
                    v-model="goodsLoading"
                    :finished="goodsFinished"
                    finished-text="没有更多了"
                    @load="goodsOnLoad">
                <div class="public-card put-storage" v-for="(talentNews,idx) in talentNewsList" :key="idx">
                    <div class="put-title">{{talentNews.className}}
                        <van-button class="btn"
                                    @click="copy()"
                                    :data-clipboard-text="talentNews.info"
                        >复制</van-button>
                    </div>
                    <div class="public-flex m-b-15">
                        <div class="put-time">链接：{{talentNews.info}}
                        </div>
                    </div>
                    <div class="public-flex m-b-15" v-if="talentNews.infoType=='0'">
                        <div class="put-time">剩余视频数：{{talentNews.unclaimedVideos}}</div>
                    </div>
                    <div class="public-flex m-b-15">
                        <div class="put-time">分发时间：{{talentNews.sendTime}}</div>
                    </div>
                        <van-uploader v-model="talentNews.imgArr1"
                                      disabled
                                      :deletable="false"
                                      accept="image"
                                      :show-upload = false
                        />
                        <van-uploader v-model="talentNews.imgArr2"
                                      disabled
                                      :deletable="false"
                                      accept="image"
                                      :show-upload = false
                        />
                        <van-uploader v-model="talentNews.imgArr3"
                                      disabled
                                      :deletable="false"
                                      accept="image"
                                      :show-upload = false
                        />

                </div>
            </van-list>
        </van-pull-refresh>

    </div>

</template>

<script>
    import {getTalentNewsList, newsImgBaseUrl,getAuthorizationLinkInfo} from "../../utils/api"
    import clipboard from 'clipboard'
    import common from "../../utils/common";
    export default {
        name: "goodsManage.vue",
        data() {
            return {
                newsImgBaseUrl,
                imageWidth: 0,
                imageHeight: 0,
                pageIndex: 1,//页码
                pageSize: 10,
                className: '',
                authorizationLink:'',
                infoTypeText: '请选择',
                talentNewsList: [],
                querySearch: {
                    className: '',//商品名称
                    infoType: ''//素材分类
                },
                infoTypeList: [
                    {
                        text: '请选择',
                        value: ''
                    },
                    {
                        text: '视频',
                        value: '0'
                    },
                    {
                        text: '分镜',
                        value: '1'
                    }
                ],
                goodsLoading: false,
                goodsFinished: false,
                showInfoTypeList: false,
                isLoadingGoods: false
            }
        },
        mounted() {
            this.infoTypeText = '视频';
            this.querySearch.infoType = '0';
            this.search();
            var w = document.documentElement.clientWidth || document.body.clientWidth;
            this.imageWidth = (w - (w / 7.5 * 0.4) - 20) / 3 + 'px'
            this.imageHeight = (w - (w / 7.5 * 0.4) - 20) / 3 / 100 * 80 + 'px';
            this.getAuthorizationLinkInfo();
        },
        methods: {
            getAuthorizationLinkInfo(){
                getAuthorizationLinkInfo({
                    uniqueNo: common.getItem('uniqueNo')
                }).then(res=>{
                    if(res.code == 200){
                        if(res.data && res.data.businessCode == 200){
                            this.authorizationLink = res.data.authorizationLink
                        } else {
                        }
                    } else {
                        this.authorizationLink = ''
                    }
                })
            },
            copy() {
                let _this = this
                let clipboardObj = new clipboard('.btn')
                clipboardObj.on('success', function(){
                    _this.$toast('商品链接复制成功')
                    clipboardObj.destroy()
                })
            },
            copyAuthorizationLink(){
                let _this = this
                if(this.authorizationLink==''||!this.authorizationLink){
                    _this.$toast('你的授权链接尚未维护，请联系管理员');
                    return;
                }
                let clipboardObj = new clipboard('.m-l-15')
                clipboardObj.on('success', function(){
                    _this.$toast('授权链接复制成功')
                    clipboardObj.destroy()
                })
            },
            search() {
                this.getTalentNewsList(true);
            },
            //上拉加载
            goodsOnLoad() {
                this.getTalentNewsList();
            },
            changeInfoType(value) {
                this.infoTypeText = value.text;
                this.querySearch.infoType = value.value;
                this.showInfoTypeList = false;
            },
            getTalentNewsList(flag) {
                if (flag) {
                    this.pageIndex = 1
                }
                let data = {
                    pageIndex: this.pageIndex,//页码
                    pageSize: this.pageSize,
                    status: 4,
                    ...this.querySearch,
                }
                getTalentNewsList(data).then(res => {
                    this.goodsLoading = false;
                    if (res.code === 200 && res.data.businessCode === 200) {
                        console.log("res.data.data:", res.data.data)
                        this.goodsFinished = !res.data.data.hasNextPage;
                        if (this.pageIndex === 1) {
                            this.talentNewsList = res.data.data.list;
                        } else {
                            this.talentNewsList = this.talentNewsList.concat(res.data.data.list);
                        }
                        for(let i= 0; i< this.talentNewsList.length; i++){
                            let talentNews=this.talentNewsList[i];
                            let arr=  JSON.parse(talentNews.imagesUrl)
                            console.log(i+"---"+arr)
                            console.log(i+"--length:"+arr.length)
                            let imgArr1=[]
                            let imgArr2=[]
                            let imgArr3=[]
                            imgArr1.push({url:arr[0].replace('fileTransferPath', newsImgBaseUrl)+ '?Authorization=' + common.getItem('wtToken')})
                            talentNews.imgArr1 = imgArr1
                            if(arr.length==2){
                                imgArr2.push({url:arr[1].replace('fileTransferPath', newsImgBaseUrl)+ '?Authorization=' + common.getItem('wtToken')})
                                talentNews.imgArr2 = imgArr2
                            }
                            if(arr.length==3){
                                imgArr2.push({url:arr[1].replace('fileTransferPath', newsImgBaseUrl)+ '?Authorization=' + common.getItem('wtToken')})
                                talentNews.imgArr2 = imgArr2
                                imgArr3.push({url:arr[2].replace('fileTransferPath', newsImgBaseUrl)+ '?Authorization=' + common.getItem('wtToken')})
                                talentNews.imgArr3 = imgArr3
                            }
                            console.log("talentNews:"+talentNews)

                        }
                        if (!this.goodsFinished) {
                            this.pageIndex++;
                        }
                    }

                })
            },
            goodsOnRefresh() {
                this.isLoadingGoods = false
                this.talentNewsList = []
                this.pageIndex = 1
                this.getTalentNewsList()
            },
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";

    .van-tab {
        font-size: 0.35rem;
    }

    .van-cell {
        font-size: 12px;
    }

    .justify_content_space_between {
        justify-content: space-between;

    }

    .news_image {
        margin-top: 10px;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }
    .btn{
        margin-top: 0.2rem;
        width: 1rem;
        height: 0.4rem;
        background: linear-gradient(270deg, #4178C0 0%, #78ADEB 100%);
        border-radius: 30px;
        font-size: 0.2rem;
        color: #fff;
        border:0;
    }
    .card-upload{
        margin-left: -110px;
        padding: 15px;
    }
</style>
